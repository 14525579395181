@import '../../../../../styles/mixins/element-mixins';
@import '../../../../../styles/mixins/layout-mixins';
@import '../../../../../styles/mixins/font-mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  margin-left: 10px;

  @include desktop-bp {
    justify-content: flex-end;
  }

  &__open {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $white;
    z-index: 1;
  }
}

.searchBackdrop {
  z-index: 2;
}

.buttonBack {
  order: 0;
  margin: 0 16px;

  svg {
    height: 20px;
    width: 20px;
    rotate: -90deg;
  }
  path {
    stroke-width: 0.8;
    stroke: $darkgrey;
  }
}

.btnSearch {
  order: 2;

  @include desktop-bp {
    order: unset;
  }

  &__open {
    path {
      stroke: $pink;
      stroke-width: 2;
    }
  }
}

.searchForm {
  display: flex;
  border-bottom: 1px solid $darkgrey;
  order: 1;
  flex-grow: 1;
  align-self: center;

  @include desktop-bp {
    order: unset;
    flex-grow: unset;
    align-self: unset;
  }
}

.searchInput {
  width: 180px;
  padding: 4px 0;
  background-color: transparent !important;
  outline: none;
  border: none;
  @include subHeading4;
  font-size: 16px;
  color: $darkgrey;
  flex-grow: 1;

  @include desktop-bp {
    @include subHeading4;
    flex-grow: unset;
  }

  &:focus {
    box-shadow: none;
  }
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
