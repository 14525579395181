@import '../../../../../styles/mixins/element-mixins';
@import '../../../../../styles/mixins/layout-mixins';
@import '../../../../../styles/mixins/font-mixins';
@import '../../../../../styles/config/variables';

.selectionContainer {
  display: flex;
  height: 100%;
  .selection {
    display: flex;
    @include subHeading6;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    height: 100%;
    align-items: center;
    padding: 0 33px;
    cursor: pointer;
  }
}

.storeLocator {
  min-width: 350px;
  position: fixed;
  right: 0;
  background-color: $tp-white;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  transition: transform 0.1s ease-out;
  transform: scaleY(0);
  transform-origin: top;
  overflow: hidden;
  border: 1px solid $tp-rebuild-grey-02;
  border-top: none;

  &__content {
    padding: 32px 0 16px 0;
    @include desktop-bp {
      min-width: 400px;
    }
  }

  &__messageContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
    align-items: flex-start;
    padding-left: 16px;
    @include desktop-bp {
      padding-left: 24px;
    }
    margin-bottom: 24px;
  }

  &__open {
    transform: scaleY(1);
  }

  &__selectMesasge {
    display: flex;
    @include subHeading6(manrope);
    text-transform: uppercase;
    color: $tp-phase2-black;
    font-size: 11px;
    letter-spacing: 2.2px;
    font-weight: 500;
  }

  &__selectedLocation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
    border: 2px solid $tp-rebuild-grey-06;
    border-radius: 3px;
    padding: 16px;
    margin: 16px 0 0 0;

    &__text {
      @include subHeading5;
    }

    &__chevron {
      height: 15px;
    }
  }

  &__optionsContainer {
    transition: transform 0.1s ease-out;
    transform-origin: top;
    transform: scaleY(1);
    width: 100%;
    overflow: hidden;
    height: auto;

    &__option {
      @include subHeading5;
      padding: 16px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      &__hasBottomBorder {
        border-bottom: 1px solid $tp-rebuild-grey-02;
      }

      &__selected {
        background-color: $tp-house-pink-20;
        font-weight: 700;
      }
      &__check {
        height: 20px;
        width: 20px;
        color: $tp-house-pink;
      }
    }
    &__option:hover {
      background-color: $tp-house-pink-20;
    }
  }

  &__alertMessage {
    display: flex;
    align-items: center;
    @include subHeading6(manrope);
    color: $tp-phase2-medium-grey;
    &__red {
      color: $tp-deep-red;
    }

    &__icon {
      height: 15px;
      margin-right: 5px;
    }

    &__options {
      display: flex;
      width: 100%;

      button {
        @include subHeading6;
        text-transform: uppercase;
        padding: 12px 16px;
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
      }
    }
  }
}
