@import '../../../../../../styles/config/_variables.scss';
@import '../../../../../../styles/mixins/font-mixins';

.drawer {
  border-top: 1px solid $darkgrey;
  background-color: $white;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: scroll;
  padding-bottom: 100px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;

  &__open {
    transform: translateX(0%);
  }

  .subNavTitle {
    display: flex;
    align-items: baseline;
    padding: 24px 16px;
    @include subHeading6(Manrope);
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    background-color: $tp-rebuild-grey-01;
    cursor: pointer;
    &__arrow {
      transform: rotate(90deg);
      margin-right: 12px;
    }
  }

  .mainCta {
    display: flex;
    align-items: center;
    @include subHeading6(Manrope);
    padding: 24px 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.2px;
    text-decoration-line: underline;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(11, 11, 11, 0.1);
    &__arrow {
      transform: rotate(-90deg);
      margin-left: 4px;
    }
  }

  .ctaContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(11, 11, 11, 0.1);
    padding: 24px 16px;
    @include subHeading6(Manrope);
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    cursor: pointer;
    &__arrow {
      transform: rotate(-90deg);
    }
  }
}

.category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(11, 11, 11, 0.1);
  padding: 20px 30px;
  cursor: pointer;
  @include subHeading6(Manrope);
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.2px;
  text-transform: uppercase;

  &__arrow {
    transform: rotate(-90deg);
  }
}

.chevronIcon {
  width: 16px;
  height: 16px;
  transition: rotate 0.3s ease-in-out;
  rotate: -180deg;

  & > path {
    stroke: $darkgrey;
  }

  &:hover > path {
    stroke: $black;
  }

  &__open {
    rotate: 0deg;
  }

  &__sub {
    width: 12px;
    height: 12px;
  }
}

.subCategoryContainer {
  padding: 20px 30px;
  border-bottom: 1px solid $darkgrey;

  .subCategoryTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h3 {
      @include subHeading5;
      font-weight: bold;
      color: $black;
    }
  }

  .subCategories {
    margin-bottom: 24px;
    padding: 0;

    .subCategory {
      margin-bottom: 10px;
      @include subHeading5;
      color: $darkgrey;
    }
  }
}

.hidden {
  display: none;
}
