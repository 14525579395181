@import '../../../../../styles/mixins/layout-mixins';
@import '../../../../../styles/mixins/font-mixins';
.container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  overflow-y: scroll;
  .titleSection {
    display: flex;
    flex-direction: column;
    gap: 4px;
    @include heading5;
    font-weight: 600px;
    padding-bottom: 24px;
  }
  .promoImagesSection {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .imageWrapper {
      height: 365px;
      width: 100%;
      position: relative;
      @include laptop-bp() {
        width: 306px;
      }
      .image {
        object-fit: cover;
      }
    }

    .linkWrapper {
      margin-top: 8px;
      display: flex;
      gap: 4px;
      .link {
        text-decoration: underline;
        width: fit-content;
        color: #333333;
        @include subHeading4($font-manrope-normal);
        letter-spacing: 2.2px;
        font-weight: 600;
        font-size: 11px;
        text-align: left;
      }
    }
  }
}
