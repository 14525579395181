@import '../../../../../styles/mixins/element-mixins';
@import '../../../../../styles/mixins/layout-mixins';
@import '../../../../../styles/mixins/font-mixins';

.navigation {
  height: 100%;
  display: flex;
  align-items: center;
}

.navigationItem {
  margin-right: 24px;
  color: $darkgrey;
  @include subHeading4;
  cursor: pointer;
}

.navigationMenu {
  position: fixed;
  left: 0;
  right: 0;
  background-color: $white;
  border-top: 1px solid $darkgrey;
  border-bottom: 0.5px solid $darkgrey;

  transition: max-height 300ms ease, opacity 300ms ease;
  max-height: 0px;
  opacity: 0;
  pointer-events: none;

  overflow-y: scroll;

  &__open {
    max-height: 80vh;
    opacity: 1;
    pointer-events: all;
    padding-bottom: 30px;
  }
}

.navigationMenu > p {
  padding: 100px;
}

.headerIcon {
  height: 28px;
  width: 28px;
  padding: 0 4px;

  &__close {
    padding: 0px;
  }
}
