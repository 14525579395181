@import '../../../../../../../styles/mixins/font-mixins';
@import '../../../../../../../styles/mixins/layout-mixins';

.additionalWrapper {
  padding-inline: 16px;
  padding-bottom: 32px;

  @include desktop-bp {
    padding-inline: 0;
  }

  .additionalCtaTitle {
    @include subHeading6(Manrope);
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.2px;
    text-transform: uppercase;
  }
  .ctasContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px 0;
    overflow-x: auto;

    @include desktop-bp {
      flex-wrap: wrap;
    }

    &__link {
      border: 0.5px solid rgba(11, 11, 11, 0.25);
      border-radius: 30px;
      padding: 2px;
      display: flex;
      align-items: center;
    }

    .additionalCta {
      display: flex;
      @include subHeading6(Manrope);
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2.2px;
      text-transform: uppercase;

      white-space: nowrap;

      &__colourSwatch {
        height: 30px;
        width: 30px;
        border-radius: 50%;

        @include desktop-bp {
          height: 28px;
          width: 28px;
          padding: 2px;
          border: 0.5px solid #e3e3e3;
        }

        &__text {
          padding: 0 5px;
          @include subHeading6(Manrope);
          font-style: normal;
          font-weight: 500;
          line-height: 17.52px;
          letter-spacing: 0.24px;
          text-transform: capitalize;
        }
        &__value {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      &__colourSwatch:hover {
        cursor: pointer;
        border: 0.5px solid rgba(11, 11, 11, 0.5);
      }
      &__value {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 0.5px solid rgba(11, 11, 11, 0.25);
        padding: 8px 16px;
        border-radius: 30px;
      }
      &__value:hover {
        cursor: pointer;
        border: 0.5px solid rgba(11, 11, 11, 0.5);
      }
    }
  }
}
