@import '../../../../../styles/mixins/element-mixins';
@import '../../../../../styles/mixins/layout-mixins';
@import '../../../../../styles/config/variables';

.iconContainer {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconImage {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
}
