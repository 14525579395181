@import '../../../../../../styles/mixins/element-mixins';
@import '../../../../../../styles/mixins/layout-mixins';
@import '../../../../../../styles/mixins/font-mixins';
@import '../../../../../../styles/config/variables';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(red($tp-house-pink-2), green($tp-house-pink-2), blue($tp-house-pink-2), 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.modal {
  display: flex;
  width: 454px;
  padding-top: 40px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  background: $tp-phase2-white;
  z-index: 1000000;
  margin: 0 5%;
  @include desktop-bp {
    margin: 0;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.icon {
  height: 28px;
  color: $tp-phase2-black;
}

.title {
  text-align: center;
  @include subHeading2('Libertanius Serif');
  font-weight: 400;
  font-size: 26px;
}

.description {
  @include paragraph-S(manrope);
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.28px;
  padding: 0 10%;
}

.cancelButton {
  display: flex;
  height: 64px;
  padding: 32px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 1px;
  border-top: 1px solid rgba(11, 11, 11, 0.1);
  width: 50%;
  text-transform: uppercase;
  @include subHeading6(manrope);
  font-size: 11px;
}

.confirmButton {
  border-radius: 0px 0px 1px 0px;
  background: $tp-phase2-black;
  color: $tp-phase2-white;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;

  button {
    display: flex;
    height: 64px;
    padding: 32px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    width: 50%;
    text-transform: uppercase;
    @include subHeading6(manrope);
    font-size: 11px;
    letter-spacing: 2.2px;
    font-weight: 500;
  }
}
