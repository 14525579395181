@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($tp-phase2-brand-pink, 0.6);
  z-index: 100;

  .container {
    width: 90%;
    max-width: 500px;
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $tp-phase2-white;

    @include laptop-bp() {
      height: 216px;
      top: 15rem;
      transform: translate(-50%, -50%);
      left: 50%;
      right: unset;
    }

    h5 {
      width: 100%;
      padding: 32px 16px;

      @include heading5;
      color: $tp-phase2-black;
      font-size: 26px;
      font-style: normal;
      line-height: normal;
      text-align: center;
      color: $tp-rebuild-grey;
    }

    .buttons {
      display: flex;
      align-items: center;

      button {
        flex: 1;
        padding: 32px;
        border-top: 1px solid $tp-phase2-light-grey;

        @include subHeading5;
        color: $tp-phase2-black;
        font-size: 11px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 2.2px;
        text-align: center;
        text-transform: uppercase;

        &:last-of-type {
          background-color: $tp-phase2-black;
          border-color: $tp-phase2-black;
          color: $tp-phase2-white;
        }
      }
    }
  }
}
