@import '../../../../../styles/mixins/element-mixins';
@import '../../../../../styles/mixins/layout-mixins';
@import '../../../../../styles/mixins/font-mixins';
@import '../../../../../styles/config/variables';

.expandableContainer {
  padding: 24px;
  border-bottom: 1px solid $tp-phase2-light-grey
}

.expandableContainer:first-of-type {
  border-top: none;
}

.footerSectionWrapper {
  display: flex;
  flex-direction: column;
}
.footerSectionTitle {
  @include subHeading5;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.footerSectionLink {
  @include link-large;
  font-weight: 500;
  margin-bottom: 12px;
}
