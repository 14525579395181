@import '../../../../styles/config/variables';
@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';
@import '../../../../styles/mixins/element-mixins.scss';
.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba($tp-pink-bulletin-pink, 0.8);

  .container {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    background-color: $tp-white;
    height: 450px;
    min-width: 100%;
    @include laptop-bp {
      top: 0;
      right: 0;
      height: 100%;
      min-width: 0px;
      max-width: 485px;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $tp-rebuild-grey-005;
    padding: 24px 32px;
    @include paragraph-XS;
    font-weight: 600;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    span {
      cursor: pointer;
    }
  }
  .itemList {
    padding: 16px 32px;
    overflow-y: auto;
  }
  .totalsWithButtons {
    text-transform: uppercase;
    margin-top: auto;
    height: 134px;
    @include laptop-bp {
      height: 324px;
    }
    .subtotal,
    .delivery,
    .total,
    .buttons {
      text-transform: uppercase;
      align-items: center;
      @include paragraph-XS;
      font-weight: 600;
      letter-spacing: 2.2px;
      display: flex;
      justify-content: space-between;
    }
    .total {
      display: flex;
      @include heading5;
      font-size: 26px;
      .estTotalWithVat {
        text-transform: none;
        align-items: baseline;
        display: flex;
        gap: 6px;
        .vat {
          @include paragraph-XS;
          font-weight: 600;
          letter-spacing: 2.2px;
        }
      }
    }
    .buttons {
      height: 100%;
      cursor: pointer;
      .viewBag {
        display: flex;
        justify-content: center;
        flex: 1;
        gap: 6px;
        padding-block: 24px;
        @include laptop-bp {
          padding-block: 32px;
        }
      }
      .goToCheckout {
        display: flex;
        justify-content: center;
        background-color: $tp-black;
        color: $tp-white;
        margin: auto;
        flex: 1;
        gap: 6px;
        padding-block: 24px;
        @include laptop-bp {
          padding-block: 32px;
        }
      }
    }
  }
}

.divider {
  padding: 16px 32px;
}
