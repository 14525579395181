@import '../../../../styles/mixins/element-mixins';
@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';
@import '../../../../styles/config/variables';

.wrapper {
  background-color: $tp-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.linksContainer {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;

  @include desktop-bp {
    padding: 32px;
    flex-direction: row;
    gap: 0;
  }
}

.menuContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  @include desktop-bp {
    width: 60%;
    flex-direction: row;
    gap: 40px;
    display: flex;
    justify-content: flex-start;
  }
}

.locationAndSocialLinksContainer {
  @include desktop-bp {
    width: 30%;
    justify-content: flex-start;
    gap: 10px;
  }
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
.locationContainer {
  @include desktop-bp() {
    height: 110px;
  }
  width: 100%;
}
.socialsContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  @include desktop-bp() {
    gap: 35px;
    justify-content: flex-start;
  }
}

.logoAndPayments {
  width: 100%;
  @include desktop-bp() {
    padding: 32px;
  }
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px rgba(10.63, 10.63, 10.63, 0.15) solid;

  .logo {
    @include desktop-bp() {
      border-bottom: none;
      gap: 12px;
      align-items: center;
      width: unset;
      justify-content: unset;
      padding: unset;
    }
    width: 100%;
    padding: 24px;
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px rgba(10.63, 10.63, 10.63, 0.15) solid;

    div {
      position: relative;
      object-position: center;
      object-fit: cover;
      width: 80px;
      height: 30px;
    }
  }
  .logoImage {
    position: relative;
    object-position: center;
    object-fit: cover;
  }
}

.logoText {
  @include subHeading5;
  text-transform: uppercase;
}

.payments {
  display: flex;
  gap: 32px;
  width: 100%;
  justify-content: space-between;
  padding: 24px;
  @include desktop-bp() {
    width: unset;
    justify-content: unset;
    padding: unset;
  }
}
