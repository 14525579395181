@import '../../../styles/mixins/font-mixins';
@import '../../../styles/config/variables';

.container {
  padding: 24px;
  border-bottom: 1px solid $tp-phase2-light-grey;
}

.container:first-of-type {
  border-top: 1px solid $tp-phase2-light-grey;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.title {
  @include subHeading6;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.arrow {
  height: 14px;
  width: 14px;
  stroke: #333333;

  transition: rotate 0.3s ease;

  &__open {
    rotate: 180deg;
  }
}

.content {
  margin-top: 16px;
  @include subHeading5;
  font-family: Proxima Nova, Arial, Helvetica, sans-serif;
  text-align: left;
}
