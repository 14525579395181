@import '../../../styles/mixins/font-mixins';
@import '../../../styles/mixins/layout-mixins';

.container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px 0;

  @include laptop-bp {
    padding: 0;
    padding-bottom: 16px;
  }
}

.productImage {
  position: relative;
  width: 53px;
  height: 63px;

  @include laptop-bp {
    width: 95px;
    height: 114px;
  }

  &__cart {
    width: 140px;
    height: 148px;
  }

  &__miniCart {
    height: 121px;
    width: 100px;
  }
}

.productInfo {
  flex: 1;

  .productNameAndPrice {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    height: 41px;
    margin-bottom: 24px;

    @include laptop-bp {
      height: 78px;
    }

    &__cart {
      height: fit-content;
      margin-bottom: 16px;
    }

    &__miniCart {
      height: fit-content;
      margin-bottom: 16px;
    }

    .productName {
      @include subHeading5;
      color: $tp-phase2-black;
      font-weight: 500;
      letter-spacing: 0.28px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

      &__clickable {
        cursor: pointer;
      }
      @include laptop-bp() {
        &:hover {
          -webkit-box-orient: unset;
        }
      }
    }

    .productPrice {
      @include subHeading5;
      color: $tp-phase2-black;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2.8px;
      text-transform: uppercase;
      text-align: right;

      &__discounted {
        color: $tp-phase2-medium-grey;
        text-decoration: line-through;
      }

      &__cart {
        font-size: 13px;
        letter-spacing: 2.6px;
      }
    }
  }

  .variantInfo {
    display: flex;
    gap: 16px;

    &__cart {
      gap: 24px;
    }

    p {
      @include subHeading5;
      color: $tp-phase2-black;
      font-size: 12px;
      font-weight: 500;
      line-height: 146%;
      letter-spacing: 0.24px;
    }
  }
}

.quantitySelectorContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  .quantitySelector {
    width: 167px;
    height: 34px;
    display: flex;
    align-items: center;
    border: 0.7px solid $tp-phase2-light-grey;

    @include paragraph-S;
    color: $tp-phase2-black;
    text-align: center;
    font-weight: 500;
    letter-spacing: 2.6px;
    text-transform: uppercase;

    span {
      flex: 1;
      text-align: center;
    }

    button {
      width: 56px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .btnRemove {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: $tp-phase2-light-grey;

    svg {
      height: 16px;
      width: 16px;
      margin: auto;
    }
  }
}

.removeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  .priceContainer {
    @include paragraph-S;
    color: $tp-phase2-medium-grey;
    font-size: 12px;
    font-weight: 500;
    line-height: 146%;
    letter-spacing: 0.24px;

    .productPrice {
      display: inline-block;
      margin-right: 16px;

      font-weight: 700;
      line-height: 146%;
      letter-spacing: 0.24px;

      &__discounted {
        margin-right: 16px;
        font-weight: 500;
        text-decoration: line-through;
      }
    }
  }

  .btnRemove {
    @include subHeading5;
    color: $tp-phase2-black;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.2px;
    text-decoration-line: underline;
    text-transform: uppercase;

    svg {
      display: inline-block;
      height: 16px;
      width: 16px;
      margin-right: 4px;
    }
  }
}
