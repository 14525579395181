@import '../../../styles/config/variables';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: $white;
  opacity: 0.8;
}
