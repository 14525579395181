@import '../../../styles/mixins/layout-mixins';
@import '../../../styles/mixins/font-mixins';
@import '../../../styles/config/variables';

.emailSignUpContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 48px;
  width: 100%;

  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 45px 147px;

  background: $tp-house-pink-2;

  flex: none;
  order: 11;
  flex-grow: 0;
  flex-shrink: 1;

  @media screen and (max-width: $screen-desktop-min) {
    flex-direction: column;
    padding: 24px 15px;
    justify-content: unset;
    gap: 16px;
  }
  .signUpText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    @include desktop-bp() {
      width: 48%;
    }

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    .title {
      @include subHeading5;
      font-weight: 500;
      color: $tp-phase2-black;
      letter-spacing: 2.2px;
      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .text {
      @include paragraph-XL('Libertinus Serif');
      color: $tp-phase2-black;
      font-size: 26px;

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
  .signUpForm {
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 48%;
    padding: 0px;
    /* Inside auto layout */

    flex: none;
    order: 1;
    @media screen and (max-width: $screen-desktop-min) {
      height: 50%;
      width: 100%;
    }
    .nameSurnameContainer {
      @include desktop-bp() {
        flex-direction: row;
        height: 56px;
        align-items: flex-start;
        gap: 12px;
      }
      padding: 0px;

      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 24px;
      .nameSurnameInput {
        display: flex;
        flex-direction: column;
        @include desktop-bp() {
          width: 50%;
        }

        input {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 16px;
          gap: 10px;
          height: 56px;

          background: $tp-house-pink-2;
          color: $tp-phase2-black;
          border: 1px solid $tp-phase2-black;

          flex: none;
          order: 0;
          flex-grow: 1;

          &::placeholder {
            @include paragraph-M;
            font-family: 'Proxima Nova';
            font-size: 16px;
            color: $tp-phase2-black;
          }

          &:focus {
            box-shadow: none;
          }
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px #000 inset;
            transition: background-color 5000s ease-in-out 0s;
          }
        }

        label {
          background-color: $tp-house-pink-2;
          color: $tp-phase2-black;
        }
      }
    }
    .emailSubmit {
      display: flex;
      @include desktop-bp() {
        flex-direction: row;
        gap: 0;
      }
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 100%;

      .emailInput {
        @include desktop-bp() {
          width: 55%;
        }
        width: 100%;
        display: flex;
        flex-direction: column;
        input {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 16px;
          gap: 10px;
          height: 56px;

          background: $tp-house-pink-2;
          color: $tp-phase2-black;
          border: 1px solid $tp-phase2-black;

          /* Inside auto layout */

          flex: none;
          order: 0;
          flex-grow: 1;

          &::placeholder {
            @include paragraph-M;
            font-family: 'Proxima Nova';
            font-size: 16px;
            color: $tp-phase2-black;
          }

          &:focus {
            box-shadow: none;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px #000 inset;
            transition: background-color 5000s ease-in-out 0s;
          }
        }
        label {
          background-color: $tp-house-pink-2;
          color: $tp-phase2-black;
        }
      }
    }
  }

  .thankYouMessage {
    @include paragraph-M;
    font-family: 'Proxima Nova';
    font-size: 16px;

    color: $tp-phase2-black;
  }

  button {
    @include desktop-bp() {
      width: 45%;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 16px 24px;
    background-color: $tp-phase2-black;
    color: $tp-white;
    @include link-large;
    font-size: 11px;
    text-transform: uppercase;
  }

  .inactiveButton {
    cursor: not-allowed;
  }

  &__error {
    input {
      border: 2px solid $tp-red !important;

      &:focus {
        border-color: $tp-red;
      }
    }

    label {
      color: $tp-red !important;
    }

    span {
      visibility: visible !important;
      color: $tp-red;
    }
  }

  .svg {
    width: 20px;
    transform: rotate(-90deg);

    & > path {
      stroke: $tp-white;
      stroke-width: 1px;
    }
    @include desktop-bp() {
      height: 18px;
    }
  }

  .signupError {
    color: $tp-white;
    margin-top: 55px;
    order: 2;
    @include desktop-bp() {
      margin-top: 10px;
    }
  }
}
