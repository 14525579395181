@import '../../../../styles/mixins/element-mixins';
@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';
@import '../../../../styles/config/variables';

.header {
  margin: 0 auto;
  z-index: 49;
  width: 100%;
  display: flex;
  height: calc(70px + $alert-banner-height-mobile);

  @include desktop-bp {
    height: calc(100px + $alert-banner-height);
  }
}

.alertBannerAndStoreLocatorWrapper {
  position: relative;
  @include desktop-bp {
    min-height: $alert-banner-height;
  }
}

.storeLocatorContainer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 35px;
  height: 100%;

  @include desktop-bp {
    min-height: 40px;
  }

  &__solidBackground {
    background-color: $tp-white;
    left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.container {
  position: fixed;
  left: 0;
  right: 0;
  min-width: 320px;
  width: 100%;
  z-index: 49;
  transition: background-color 0.3s;
  &__collapsed {
    background-color: white;
  }
  @include desktop-bp {
    &:hover {
      background-color: white;
    }
  }
}

.wrapper {
  max-width: $content-max-width;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 34px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
  height: 70px;

  transition: height 0.5s;

  @include desktop-bp {
    height: 100px;
    min-height: 48px;
  }

  &__collapsed {
    height: 70px;
  }

  &__mobileNavigationIsOpen {
    background-color: white;
  }
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 34px;
  width: 100px;
  transform: translate(-50%, -50%);

  span img {
    object-fit: contain;
  }

  @include desktop-bp {
    height: 48px;
    width: 141px;
  }
}

.accountIcon {
  &:hover {
    cursor: pointer;
  }
}

.accountDropdownContainer {
  position: relative;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;

  &__active {
    background: $tp-rebuild-grey;

    svg path {
      stroke: $white;
    }
  }

  .accountDropdownWrapper {
    display: block;
    position: absolute;
    top: 38px;
    right: 0;
    width: 150px;

    &__hidden {
      display: none;
    }
  }
}

.accountDropdown {
  background: $tp-rebuild-grey;

  li {
    a {
      padding: 5px 15px;
      display: block;
      color: $tp-rebuild-grey-02;
      border-top: 1px solid $tp-rebuild-grey-06;

      &:hover {
        background: $tp-rebuild-lightest-grey;
      }
    }

    &:first-child {
      a {
        border-top: none;
      }
    }
  }
}

.cartContainer {
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}

.bagItemIcon {
  position: absolute;
  top: 16px;
  right: 2px;
  display: flex;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #000;
  font-size: 10px;
  color: #fff;
}
