@import '../../../../../../../styles/mixins/font-mixins';
@import '../../../../../../../styles/mixins/layout-mixins';

.imageCtasContainerInline {
  display: flex;
  width: 100%;
  column-gap: 8px;
  padding-top: 30px;
  padding-inline: 16px;

  @include desktop-bp {
    width: 40%;
    padding-inline: 0;
  }

  .cardContainer {
    flex: 1;

    &__title {
      @include subHeading6(Manrope);
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2.2px;
      text-decoration-line: underline;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }

    .imageContainer {
      width: 100%;
      position: relative;
      padding-top: 100%;
      margin-bottom: 16px;

      &__image {
        object-fit: cover;
      }
    }
  }
}

.imageCtasContainerGrid {
  display: grid;
  width: 100%;
  row-gap: 24px;
  column-gap: 8px;
  padding: 30px 0;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding-inline: 16px;

  @include desktop-bp {
    width: 40%;
    height: 50%;
    padding-inline: 0;
  }

  .cardContainer {
    &__title {
      @include subHeading6(Manrope);
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2.2px;
      text-decoration-line: underline;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }

    .imageContainer {
      width: 100%;
      position: relative;
      margin-bottom: 16px;

      &__image {
        object-fit: cover;
      }
    }
  }
}

.arrowIcon {
  transform: rotate(-90deg);
  margin-left: 6px;
}
