@import '../../../../../styles/mixins/element-mixins';
@import '../../../../../styles/mixins/layout-mixins';
@import '../../../../../styles/mixins/font-mixins';
@import '../../../../../styles/config/variables';

.storeLocatorWrapper {
  display: flex;
  flex-direction: column;

  p {
    @include subHeading5;
    margin-bottom: 16px;
    text-transform: uppercase;
    word-wrap: break-word;
  }
}

.storeLocator {
  min-width: 350px;
  position: fixed;
  right: 0;
  background-color: $tp-white;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  transition: transform 0.1s ease-out;
  transform: scaleY(0);
  transform-origin: top;
  overflow: hidden;
  border: 1px solid $tp-rebuild-grey-02;
  border-top: none;

  &__selectedLocation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
    border: 2px solid $tp-rebuild-grey-06;
    border-radius: 3px;
    padding: 16px;
    margin: 0;

    &__text {
      @include subHeading5;
    }

    &__chevron {
      height: 15px;
    }
  }

  &__optionsContainer {
    z-index: 100;
    background-color: $tp-white;
    transition: transform 0.1s ease-out;
    transform-origin: top;
    transform: scaleY(1);
    width: 100%;
    overflow: hidden;
    height: auto;
    border: 1px solid $tp-rebuild-grey-06;
    visibility: hidden;

    &__option {
      @include subHeading5;
      padding: 16px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      &__hasBottomBorder {
        border-bottom: 1px solid $tp-rebuild-grey-02;
      }

      &__selected {
        background-color: $tp-house-pink-20;
        font-weight: 700;
      }
      &__check {
        height: 20px;
        width: 20px;
        color: $tp-house-pink;
      }
    }
    &__option:hover {
      background-color: $tp-house-pink-20;
    }
    &__open {
      visibility: visible;
    }
  }
}

.expandableContainer {
  padding: 24px;
  border-bottom: 1px solid $tp-phase2-light-grey;
}

.expandableContainer:first-of-type {
  border-top: none;
}
.expandableContainerText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  @include subHeading6;
  text-transform: uppercase;
}
