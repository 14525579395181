@import '../../../styles/mixins/layout-mixins';

.listItem {
  border-bottom: 1px solid $tp-phase2-light-grey;

  &:last-of-type {
    border-bottom: none;
  }

  @include laptop-bp {
    &:last-of-type {
      border-bottom: 1px solid $tp-phase2-light-grey;
    }
  }
}
