@import '../../../../../../styles/mixins/element-mixins';
@import '../../../../../../styles/mixins/layout-mixins';
@import '../../../../../../styles/mixins/font-mixins';
@import '../../../../../../styles/config/variables';

.subCategoryContainer {
  display: flex;
  justify-content: center;
  max-width: $content-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-inline: 16px;

  .ctasContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 60%;

    .mainCta {
      @include subHeading6(Manrope);
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2.2px;
      text-decoration-line: underline;
      text-transform: uppercase;
      padding-bottom: 40px;
      padding-top: 31px;

      display: flex;
      align-items: center;

      &__arrow {
        transform: rotate(-90deg);
        margin-left: 5px;
        height: 20px;
      }
    }
    .mainCta:hover {
      cursor: pointer;
    }

    .columnsContainer {
      background-color: white;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 40px;
      padding-bottom: 48px;

      .columnSection {
        display: flex;
        flex: 1;
        flex-direction: column;

        .sectionTitle {
          @include subHeading6(Manrope);
          font-weight: 500;
          line-height: normal;
          letter-spacing: 2.2px;
          text-transform: uppercase;
          padding-bottom: 10px;
        }
        .sectionCta {
          @include subHeading5(Manrope);
          font-weight: 500;
          line-height: 150%;
          letter-spacing: 0.28px;
          padding: 6px 0;
        }
        .sectionCta:hover {
          cursor: pointer;
        }
      }
    }

    .additionalWrapper {
      padding-bottom: 32px;

      .additionalCtaTitle {
        @include subHeading6(Manrope);
        font-weight: 500;
        line-height: normal;
        letter-spacing: 2.2px;
        text-transform: uppercase;
      }
      .ctasContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 16px;
        flex-wrap: wrap;
        padding-top: 16px;

        .additionalCta {
          display: flex;
          @include subHeading6(Manrope);
          font-weight: 500;
          line-height: normal;
          letter-spacing: 2.2px;
          text-transform: uppercase;

          border: 0.5px solid rgba(11, 11, 11, 0.25);
          padding: 8px 16px;
          border-radius: 30px;

          &__colourSwatch {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            padding: 0;
          }
        }
        .additionalCta:hover {
          cursor: pointer;
        }
      }
    }
  }
}
